import { Link, ResponsiveImage, StandardTile, StandardTileContents, StandardTileImage, Typography } from '@vp/swan'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classes, { stylesheet } from './LogoBanner.module.scss'
import { getRedirectURL } from '../../../utilities/redirect'
import { useStyles } from '@vp/ubik-context'
import { isClient } from '../../../utilities/isClient'
import { useEventTracking } from '../../../contexts/EventTrackingContext'

const LogoBanner: FC<{ bgColor: string; logoUrl: string }> = ({ bgColor, logoUrl }) => {
  const { t } = useTranslation()
  useStyles(stylesheet)
  const finalTextColor = bgColor === '#000000' ? 'white' : 'black'

  const { trackNavigationClicked } = useEventTracking()

  return (
    <StandardTile style={{ background: `${bgColor}` }} className={classes.mainContainer}>
      <ResponsiveImage
        crossOrigin="anonymous"
        className={classes.logoImage}
        src={logoUrl}
        alt={t('shareablePage.logoImageBanner.imageAltText')}
      />
      <div style={{ color: `${finalTextColor}` }} className={classes.tileContents}>
        <Typography className={classes.logoImageDescription1} fontWeight="bold">
          {t('shareablePage.logoImageBanner.createdOnTitle')}
        </Typography>
        {isClient() && (
          <Link
            className={classes.logoImageDescription2}
            onClick={() =>
              trackNavigationClicked({
                label: 'Create yours today',
                navigationDetail: 'Logo Design',
              })
            }
            href={getRedirectURL('/logo-design')}
          >
            {t('shareablePage.logoImageBanner.createYoursTodayText')}
          </Link>
        )}
      </div>
    </StandardTile>
  )
}

export default LogoBanner
