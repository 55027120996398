import { SWAN_STYLE_KEY_MAP } from '@vp/swan'
import { Helmet } from 'react-helmet'
import { IdentityProvider, useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import ShareablePage from './ShareablePage/ShareablePage'
import i18n from '../i18n/i18n'
import { PAGE_NAME } from '../utilities/events'
import { EventTrackingContextProvider } from '../contexts/EventTrackingContext'
import { GetShareableLogoResponse } from '../types/GetShareableLogoResponse'
import NotFoundPage from './ErrorPages/NotFoundPage'
import { getCultureSpecificConfig } from '../configurations/cultureConfig'
import { useTranslation } from 'react-i18next'

export interface Props {
  logoData?: GetShareableLogoResponse | null
  locale: string
  logoId: string
}

export const ShareableFragment = ({ locale, logoData = null, logoId }: Props) => {
  const createAuthConfig = (culture: string, isDevelopment = false) => ({
    culture,
    developmentMode: isDevelopment,
    options: { requireSession: true, customText: t('account.signIn') },
  })
  const { t } = useTranslation()
  const { homeURL } = getCultureSpecificConfig(locale)
  const logger = useLogger()
  logger.log('Rendering Shareable fragment for a locale', locale)

  i18n.changeLanguage(locale)

  const styleSheetKeys = [
    SWAN_STYLE_KEY_MAP.button,
    SWAN_STYLE_KEY_MAP.accordion,
    SWAN_STYLE_KEY_MAP.grid,
    SWAN_STYLE_KEY_MAP.modalDialog,
    SWAN_STYLE_KEY_MAP.hidden,
    SWAN_STYLE_KEY_MAP.icon,
    SWAN_STYLE_KEY_MAP.toggleSwitch,
    SWAN_STYLE_KEY_MAP.core,
    SWAN_STYLE_KEY_MAP.visible,
    SWAN_STYLE_KEY_MAP.standardTile,
    SWAN_STYLE_KEY_MAP.progressiveImage,
    SWAN_STYLE_KEY_MAP.utility,
    SWAN_STYLE_KEY_MAP.tabs,
    SWAN_STYLE_KEY_MAP.alertBox,
    SWAN_STYLE_KEY_MAP.popover,
    SWAN_STYLE_KEY_MAP.banner,
    SWAN_STYLE_KEY_MAP.spinner,
    SWAN_STYLE_KEY_MAP.fieldSet,
  ]
  useSwanStyleKeys(styleSheetKeys)
  return (
    <>
      <Helmet>
        <title>{logoData?.companyName}</title>
      </Helmet>
      <IdentityProvider auth={createAuthConfig(locale || 'en-us')}>
        <EventTrackingContextProvider pageName={PAGE_NAME.SHAREABLE_LOGO} locale={locale}>
          {logoData ? <ShareablePage {...logoData} logoId={logoId} /> : <NotFoundPage finalHomeURL={homeURL} />}
        </EventTrackingContextProvider>
      </IdentityProvider>
    </>
  )
}
