import { FC } from 'react'
import classes from './ErrorPages.module.scss'
import { Button, Link, Typography } from '@vp/swan'
import { getCultureSpecificConfig } from '../../configurations/cultureConfig'
import { getCookie } from '../../utilities/cookies'
import { useUserContext } from '@vp/ubik-context'

type ErrorProps = {
  heading: string
  description: string
  buttonText: string
  image: string
}

const ErrorDisplay: FC<ErrorProps> = ({ heading, description, buttonText, image }) => {
  const locale = useUserContext().locale
  const { homeURL } = getCultureSpecificConfig(locale)
  return (
    <>
      <div className={classes.errorMoreInfoContainer}>{`${
        getCookie('QuantumMetricSessionID') ? getCookie('QuantumMetricSessionID') : ''
      }`}</div>
      <div className={classes.subContainer}>
        <div className={classes.textContainer}>
          <h1 className={classes.heading}>{heading}</h1>
          <Typography className={classes.description}>{description}</Typography>
          <Button
            mt={'6'}
            skin="primary"
            render={props => (
              <Link tabIndex={0} className={props.className} to={`${homeURL}`}>
                {props.children}
              </Link>
            )}
          >
            {buttonText}
          </Button>
        </div>
        <div>
          <img src={image} alt={'Error Page'} className={classes.img} />
        </div>
      </div>
    </>
  )
}

export default ErrorDisplay
