import React from 'react'
import { GenerateDesignProduct } from '../../api/smartDesignEngine'
import productData from '../../configurations/products-scene.json'
import { Box } from '@vp/swan'
import { ImageWithDimensions } from '../../utilities/image'
import CustomSceneImage from '../CustomSceneImage/CustomSceneImage'

interface ProductShowCaseProps {
  logo: ImageWithDimensions
  mvpId: string
  backgroundColor: string
}

type ProductData = {
  [key: string]: {
    productDetails: GenerateDesignProduct
    sceneId: string
    productName: string
  }
}

const typedProductData = productData as ProductData

const getProduct = (productKey: string) => {
  return typedProductData[productKey] || null
}

const ProductShowCase: React.FC<ProductShowCaseProps> = ({ logo, mvpId, backgroundColor }) => {
  const product = getProduct(mvpId)

  return (
    <Box>
      {product && (
        <Box>
          <CustomSceneImage
            productDetails={product.productDetails}
            sceneId={product.sceneId}
            backgroundColor={backgroundColor}
            logo={logo}
            width="2000"
            productName={product.productName}
          />
        </Box>
      )}
    </Box>
  )
}

export default React.memo(ProductShowCase)
