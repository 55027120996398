import { ResponsiveImage, StandardTile } from '@vp/swan'
import { FC } from 'react'
import classes, { stylesheet } from './WebsiteBanner.module.scss'
import websiteLaptop from '../../../assets/shareable-page/website-laptop.png'
import websitePhone from '../../../assets/shareable-page/website-phone.png'
import swiglyImage from '../../../assets/shareable-page/swigly-image.png'
import { useStyles } from '@vp/ubik-context'
import { useTranslation } from 'react-i18next'

const WebsiteBanner: FC<{ bgColor: string; logoUrl: string }> = ({ bgColor, logoUrl }) => {
  useStyles(stylesheet)
  const { t } = useTranslation()

  return (
    <StandardTile className={classes.baseContainer} style={{ background: `${bgColor}` }}>
      <div className={classes.websiteImagesContainerOverlay} />
      <div className={classes.websiteLaptopPhoneContainer}>
        <div className={classes.websiteLaptopImgContainer}>
          <ResponsiveImage
            src={websiteLaptop}
            className={classes.websiteLaptopImg}
            alt={t('shareablePage.websiteImageBanner.websiteOnLaptopAltText')}
          />
          <ResponsiveImage
            crossOrigin="anonymous"
            style={{ background: `${bgColor}` }}
            className={classes.laptopContent}
            src={logoUrl}
            alt={t('shareablePage.websiteImageBanner.logoImageAltText')}
          />
        </div>
        <div className={classes.websitePhoneImgContainer}>
          <ResponsiveImage
            src={websitePhone}
            className={classes.websitePhoneImg}
            alt={t('shareablePage.websiteImageBanner.websiteOnPhoneAltText')}
          />
          <ResponsiveImage
            crossOrigin="anonymous"
            style={{ background: `${bgColor}` }}
            className={classes.phoneContent}
            src={logoUrl}
            alt={t('shareablePage.websiteImageBanner.logoImageAltText')}
          />
        </div>
      </div>
      <div className={classes.swiglyImgContainer}>
        <ResponsiveImage src={swiglyImage} className={classes.swiglyImg} alt={t('shareablePage.websiteImageBanner.swiglyImageAltText')} />
      </div>
      <div style={{ background: `${bgColor}` }}>
        <div className={classes.websiteViewContainerOverlay}></div>
      </div>
    </StandardTile>
  )
}

export default WebsiteBanner
