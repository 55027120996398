import { useEffect, useState } from 'react'
import LogoBanner from './LogoBanner/LogoBanner'
import ProductShowCase from '../ProductShowCase/ProductShowCase'
import { VistaLogoHeader } from '../VistaLogoHeader/VistaLogoHeader'
import WebsiteBanner from './WebsiteBanner/WebsiteBanner'
import { useEventTracking } from '../../contexts/EventTrackingContext'
import { getImageDimensions } from '../../utilities/image'
import { createLogoVariantUrlGenerator } from '../../utilities/createLogoVariantUrlGenerator'
import Loader from '../Loader/Loader'
import classes, { stylesheet } from './ShareablePage.module.scss'
import { useStyles } from '@vp/ubik-context'

interface ShareablePageProps {
  logoId: string
  logoVariantSignedToken: string
  backgroundColor: string
}

const ShareablePage = ({ logoId, logoVariantSignedToken, backgroundColor }: ShareablePageProps) => {
  useStyles(stylesheet)
  const [logoDimensions, setLogoDimensions] = useState<{ height: number; width: number } | null>(null)
  const logoUrlGenerator = createLogoVariantUrlGenerator(logoId, logoVariantSignedToken)
  const { trackPage } = useEventTracking()
  const productsArray1 = ['standardBusinessCards', 'premiumBallpointPens']
  const productsArray2 = ['mensPremiumShortSleeveTShirts', 'personalizedMugs']

  useEffect(() => {
    trackPage()
  }, [])

  const logoUrl = logoUrlGenerator({
    backgroundMode: 'TRANSPARENT',
    imageType: 'PNG',
    width: 2000,
  })

  const logoUrlForWebsiteBanner = logoUrlGenerator({
    backgroundMode: 'TRANSPARENT',
    imageType: 'PNG',
    width: 500,
    height: 500,
  })

  useEffect(() => {
    getImageDimensions(logoUrl).then(setLogoDimensions)
  }, [logoId, logoUrl])

  return (
    <>
      <VistaLogoHeader logoRedirectionUrl={'/'} />
      <LogoBanner bgColor={backgroundColor} logoUrl={logoUrl} />
      {!logoDimensions && <Loader />}

      {logoDimensions && (
        <>
          <div className={classes.productWrapper}>
            {productsArray1.map(product => (
              <ProductShowCase
                key={product}
                mvpId={product}
                backgroundColor={backgroundColor}
                logo={{
                  url: logoUrl,
                  width: logoDimensions.width,
                  height: logoDimensions.height,
                }}
              />
            ))}
          </div>
          <WebsiteBanner bgColor={backgroundColor} logoUrl={logoUrlForWebsiteBanner} />
          <div className={classes.productWrapper}>
            {productsArray2.map(product => (
              <ProductShowCase
                key={product}
                mvpId={product}
                backgroundColor={backgroundColor}
                logo={{
                  url: logoUrl,
                  width: logoDimensions.width,
                  height: logoDimensions.height,
                }}
              />
            ))}
          </div>
        </>
      )}
    </>
  )
}

export default ShareablePage
