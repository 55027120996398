import React from 'react'
import classes, { stylesheet } from './Skeleton.module.scss'
import { useStyles } from '@vp/ubik-context'

interface SkeletonProps {
  className: string
}

const Skeleton: React.FC<SkeletonProps> = ({ className }) => {
  useStyles(stylesheet)
  return <div className={`${classes.skeleton}  ${className}`} />
}

export default Skeleton
