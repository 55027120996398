import React, { useEffect, useState } from 'react'
import { ImageWithDimensions } from '../../utilities/image'
import { GenerateDesignProduct } from '../../api/smartDesignEngine'
import { generateDesignDocumentForProduct } from '../../utilities/customDesignDocument/generateDesignDocument'
import { generatePreviewURI } from '../../utilities/customDesignDocument/utils'
import Skeleton from '../SceneSkeleton/Skeleton'
import { ResponsiveImage } from '@vp/swan'
import classes, { stylesheet } from './CustomSceneImage.module.scss'
import { useStyles, useUserContext } from '@vp/ubik-context'

interface CustomSceneImageProps {
  productDetails: GenerateDesignProduct
  sceneId: string
  backgroundColor: string
  logo: ImageWithDimensions
  width: string
  productName: string
}

const CustomSceneImage: React.FC<CustomSceneImageProps> = ({ productDetails, sceneId, backgroundColor, logo, width, productName }) => {
  useStyles(stylesheet)
  const [imageUrl, setImageUrl] = useState<string>('')
  const locale = useUserContext().locale

  useEffect(() => {
    const createCustomDesign = async () => {
      setImageUrl('')
      const cimDoc = await generateDesignDocumentForProduct(productDetails, locale, logo, backgroundColor)
      setImageUrl(generatePreviewURI(cimDoc, width, sceneId, productDetails.mpvId))
    }

    createCustomDesign()
  }, [logo])

  return (
    <>
      {imageUrl === '' && <Skeleton className={classes.showCaseWrapper} />}
      {imageUrl && (
        <ResponsiveImage
          src={imageUrl}
          alt={productName}
          className={`${classes.sceneImage}`}
          style={{ display: imageUrl === '' ? 'none' : 'block' }}
        />
      )}
    </>
  )
}

export default CustomSceneImage
